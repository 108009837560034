@use 'styles' as theme;
.root {
  margin: 0 auto;
  display: flex;
}

.image {
  object-fit: contain;
  object-position: bottom;
  max-width: 100%;
  height: auto;
}

.mileageBadge {
  @include theme.hideUntilDesktop;

  position: absolute;
  right: 28%;
  bottom: 30%;
}

.damageBadge {
  bottom: 24%;
  left: 30%;
  transform: translateX(-50%);

  @include theme.mediaDesktopUp {
    bottom: 22%;
    left: 25%;
  }
}

.rollbackBadge {
  position: absolute;
  bottom: 8%;
  left: 62%;
  transform: translateX(-50%);
  white-space: nowrap;

  @include theme.mediaDesktopUp {
    bottom: auto;
    top: 85%;
    left: 52%;
  }
}
